import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BuscadorNuevo = () => {
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            if (searchInput.trim().length > 0) {
                navigate(`/productos/${encodeURIComponent(searchInput)}`);
            }
        }
    };

    return (
        <Form>
            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder="Buscar productos..."
                    value={searchInput}
                    onChange={handleInputChange}
                    onKeyDown={handleSearchSubmit}
                />
                <Button variant="outline-secondary" onClick={handleSearchSubmit}>
                    <i className="bi bi-search"></i> Buscar
                </Button>
            </InputGroup>
        </Form>
    );
};

export default BuscadorNuevo;
