import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { capacitaciones } from '../../config/config';
import { Card, Row, Col } from 'react-bootstrap';

const country = process.env.REACT_APP_COUNTRY;
const capacitacionesPais = capacitaciones[country];

export const Capacitaciones = () => {
  const handleClick = (imageUrl) => {
    if (imageUrl) {
      Swal.fire({
        imageUrl: imageUrl,
        imageWidth: 1000,
        imageHeight: 500,
        imageAlt: 'Custom image',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'No hay imagen disponible',
        text: 'Esta capacitación no tiene una imagen asociada.',
      });
    }
  };

  const renderCapacitaciones = () => {
    if (!capacitacionesPais || capacitacionesPais.length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2em' }}>
          <h1 style={{ textAlign: 'center', fontSize: '2em', color: 'gray' }}>
            No hay capacitaciones disponibles para este país.
          </h1>
        </div>
      );
    }

    // Voy a miostrar solo las cpaacitaciones que todavia no pasaron
    const capacitacionesValidas = capacitacionesPais.filter((capacitacion) => {
      const fechaCapacitacionISO = new Date(capacitacion.fecha).toISOString(); // Convertir a ISO 8601
      const fechaCapacitacion = new Date(fechaCapacitacionISO).getTime();  // Convertir a timestamp
      const fechaActual = new Date().getTime();  // Obtener la fecha actual en timestamp
      return fechaCapacitacion > fechaActual;  // Devuelvo solo las capacitaciones proximas y no la sque pasaron
    });



    if (capacitacionesValidas.length === 0) {
      return (

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2em' }}>
          {/* <img src={process.env.PUBLIC_URL + "banner/nuestraEmpresa.jpg"} alt="Imagen marcas" className='imagenesBody' loading='lazy'/> */}
          <h1 style={{ textAlign: 'center', fontSize: '2em', color: 'gray', marginTop: '1em', marginBottom: '1em' }}>
            No hay capacitaciones futuras disponibles.
          </h1>
        </div>
      );
    }

    return capacitacionesValidas.map((capacitacion, index) => (

      <Card key={index} className="m-5">
        <Row className="g-0">
          <Col md="3">
            <Card.Img
              onClick={() => handleClick(capacitacion.imagen)}
              className="imagenCardCapacitaciones"
              src={capacitacion.imagen}
              alt="imagen capacitacion"
            />
          </Col>
          <Col md="8">
            <Card.Body>
              <Card.Title>{capacitacion.titulo}</Card.Title>
              <Card.Text>
                <b>Horario</b>
                <p className="text-muted">{capacitacion.horario}</p>
                <b>Fecha</b>
                <p className="text-muted">{capacitacion.fecha}</p>
              </Card.Text>
              <a href={capacitacion.inscripcionLink} className="butnCapacitaciones btn btn-primary">
                Inscríbete
              </a>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    ));
  };

  return <div>
    <img src={process.env.PUBLIC_URL + "banner/capacitaciones.jpg"} alt="Imagen capacitaciones" className='imagenesBody' loading='lazy' />
    {renderCapacitaciones()}
  </div>;
};
