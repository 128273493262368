/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useContext } from 'react'
import BtnPostAdd from './BtnPostAdd'
import { toast } from 'react-toastify';
import { cartContext } from '../Context/CartContext';
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';


const AddItem = ({ producto }) => {
    const { addToCart, cartLocal } = useContext(cartContext)
    const [contador, setContador] = useState(1)
    const { grid } = useContext(globalContext);

    window.scrollTo(0, window.scrollY);

    if (!producto || !producto.Stock || producto.Stock <= 0) {
        return (
            <p style={{
                color: '#6B7280',
                fontWeight: 'bold',
                textDecoration:'underline',
                marginTop:'1em'
            }}>Producto sin stock</p>
        );
    }

    const sumar = () => {
        if (contador < producto.Stock) {
            setContador(contador + 1)
        }
        else {
            return false
        }
    }
    const restar = () => {
        if (contador > 1) {
            setContador(contador - 1)
        }
        else {
            return false
        }
    }

    const itemInCart = cartLocal.length === 0 ? undefined : cartLocal.find(item => item.Alias === producto.Alias);

    const onAdd = () => {
        if (!itemInCart || itemInCart.length === 0) {
            addToCart(producto, contador)
        }
        else if (producto.Stock - itemInCart.contador - contador >= 0) {
            addToCart(producto, contador)
        }
        else {
            toast.error(t("producto.stock-insuficiente"), {
                autoClose: 400
            });
        }
    }
    const [t] = useTranslation("global")

    if (itemInCart) return <BtnPostAdd producto={producto} />

    else {

        return (
            <div className={`${grid && 'flexGridAddCartAddItem'} ${!grid && 'd-flex justify-content-around flex-row-reverse gap-3'}`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2px',

                        borderRadius: '5px',
                        width: 'fit-content',
                    }}
                >
                    {/* Resta */}
                    <button
                        className="counter-button minus"
                        onClick={restar}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1.5rem',
                            width: '30px',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#475569',
                            transition: 'background-color 0.2s ease-in-out',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            style={{ width: '22px', height: '22px' }}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                    </button>

                    {/* Contador */}
                    <span
                        style={{
                            fontSize: '1rem',
                            margin: '0 4px',
                            color: '#475569',
                        }}
                        className="counter-value"
                    >
                        {contador}
                    </span>

                    {/* Suma */}
                    <button
                        className="counter-button plus"
                        onClick={sumar}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1.5rem',
                            width: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#475569',
                            transition: 'background-color 0.2s ease-in-out',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            style={{ width: '22px', height: '22px' }}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                    </button>
                </div>


                {
                    grid ?
                        <div style={{ fontSize: "36px", cursor: "pointer", display: "flex", alignItems: "center" }} className='iconAgregarCarrito'>
                            <Button
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5rem',
                                    marginLeft: '0.25rem',
                                    padding: '0.25rem 0.5rem',
                                    fontSize: '0.75rem',
                                    justifyContent: 'center',
                                    minWidth: '120px',
                                }}
                                variant="outline-primary"
                                onClick={onAdd}

                            >
                                Agregar
                            </Button>
                        </div>
                        :
                        <Button onClick={onAdd} style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            padding: '0.25rem 0.5rem',
                            fontSize: '0.75rem',
                            justifyContent: 'center',
                            minWidth: '120px',
                        }} variant="outline-success">
                            {t("producto.agregar-carrito")}
                        </Button>
                }
            </div>
        )
    }
}
export default AddItem