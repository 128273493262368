/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiBadgeCheck } from "react-icons/hi";

// eslint-disable-next-line no-unused-vars
const FiltroMarcas = ({ marcas, query }) => {
    const navigate = useNavigate();

    const displayedMarcas = marcas.slice(0, 7);

    return (
        <div>
            {marcas.length > 0 && (
                <div>
                    <h4 style={{ marginBottom: '1em' }}>Marca</h4>
                    {displayedMarcas.map((marca, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5em', cursor: 'pointer' }}>
                            <div>
                                <h5 style={{ margin: 0, fontSize: '1em', display: 'flex', alignItems: 'center' }}>
                                    {marca.Descripcion}
                                    <HiBadgeCheck style={{ marginLeft: '0.2em', color: '#007bff', fontSize: '1.5em' }} />
                                </h5>
                                <p
                                    className="text-ver-producto-link"
                                    onClick={() => navigate(`/marcas/${marca.Codigo}`)}
                                >
                                    Ver productos
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FiltroMarcas;
