// src/pages/HomePage.jsx
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { SpinnerGif } from '../helpers/SpinnerGif';
import Slider from '../Slider/Slider';
import './Imagenes.css';
import { cartContext } from '../Context/CartContext';
import { authContext } from '../Context/AuthContext';
import { ButtonWebCat } from '../ButtonWebCat/ButtonWebCat';
import { BtnPromociones } from '../BtnPromociones/BtnPromociones';
import { FooterMapa } from '../FooterMapa/FooterMapa';
import OfertaBanner from '../OfertaBanner/OfertaBanner';
import Banner from '../shared/Banner/Banner';
import { bannersHome } from '../../config/config';
import { CarruselMarcas } from '../CarruselMarcas/CarruselMarcas';
import PopUp from '../shared/PopUp/PopUp';
//import InstagramApp from '../RedesSociales/InstagramApp';

const Highlighters = lazy(() => import('./Highlighters'));

export const HomePage = () => {
  const country = process.env.REACT_APP_COUNTRY;

  const { getCartByUser } = useContext(cartContext);
  const { token } = useContext(authContext);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    if (!token) return;
    getCartByUser();
  }, [])

  // Declaro funcion para controlar el rango de muetreo de popup
  const isWithinDateRange = (startDate, endDate) => {
    const currentDate = new Date();
    return currentDate >= startDate && currentDate <= endDate;
  };

  useEffect(() => {
    // Muestro solo en ARGENTINA por ahora
    if (country === "ARG") {
      const startDate = new Date('2024-12-15');
      const endDate = new Date('2024-12-31');
      if (isWithinDateRange(startDate, endDate)) {
        setShowPopUp(true);
      }
    }
  }, [country]);

  const handleClose = () => setShowPopUp(false);

  return (
    <div className="background">
      <PopUp
        show={showPopUp}
        handleClose={handleClose}
      />

      <Slider />

      <ButtonWebCat />

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <Container></Container>
      </Suspense>

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <BtnPromociones />
      </Suspense>

      {country === 'UY' || country === 'ARG'
        && (
          <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
            <Highlighters />
          </Suspense>
        )}


      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <OfertaBanner />
      </Suspense>

      {country && bannersHome[country] && bannersHome[country].map((banner, index) => (
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })} key={index}>
          <Banner
            src={banner.src}
            srcEn={banner.srcEn}
            alt={banner.alt}
            href={banner.href}
            email={banner.email}
          />
        </Suspense>
      ))}

      {/* {country === 'ARG' && (
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <InstagramApp />
        </Suspense>
      )} */}

      {(country === 'USA' || country === 'CR' || country === 'DO' || country === 'GT' || country === 'HN' || country === 'UY' || country === 'SV' || country === 'ARG') && (
        <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
          <CarruselMarcas />
        </Suspense>
      )}

      <Suspense fallback={SpinnerGif({ text: 'CARGANDO' })}>
        <FooterMapa />
      </Suspense>
    </div>
  );
};

