/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Spinner, Alert, Row, Col, Container } from 'react-bootstrap';
import { HiBadgeCheck } from "react-icons/hi";
import { AiFillThunderbolt } from "react-icons/ai";
import PaginationProducts from './PaginationProducts';

const imgPlaceholder = `${process.env.PUBLIC_URL}/assets/sinfoto.png`;

const ProductosFiltradosContent = ({ productos, loading, error, currentPage, totalPages, handlePageChange, handleCardClick }) => {
    if (loading) {
        return (
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col md={6} className="text-center">
                        <Spinner animation="border" variant="primary" style={{ width: '3.5rem', height: '3.5rem' }} />
                    </Col>
                </Row>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="my-5">
                <Row className="justify-content-center">
                    <Col md={6} className="text-center">
                        <Alert variant="danger">No se pudieron obtener los productos.</Alert>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Row>
            <Col md={12}>
                {productos.length === 0 ? (
                    <Container className="my-5">
                        <Row className="justify-content-center">
                            <Col md={6} className="text-center">
                                <h3>No se han encontrado productos</h3>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    productos.map((producto, index) => (
                        <Card
                            key={index}
                            className="mb-2 d-flex align-items-center flex-row card-hover"
                            style={{ backgroundColor: 'white', cursor: 'pointer' }}
                            onClick={() => handleCardClick(producto.Alias)}
                        >
                            <Card.Img
                                variant="top"
                                src={producto.Imagenes
                                    ? process.env.PUBLIC_URL +
                                    `/articulos/thumbs/${encodeURIComponent(producto.Imagenes.split(',')[0])}`
                                    : imgPlaceholder}
                                style={{
                                    width: '100%',
                                    maxWidth: '150px',
                                    maxHeight: '150px',
                                    objectFit: 'contain',
                                    marginRight: '15px',
                                    padding: '1em',
                                }}
                            />
                            <Card.Body>
                                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '0.9em' }}>
                                    {producto?.Marca || ''}
                                    <HiBadgeCheck style={{ marginLeft: '12px', color: '#007bff', fontSize: '1.5em' }} />
                                </Card.Title>
                                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.1em' }}>
                                    {producto?.Nombre || ''}
                                </Card.Title>
                                <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '0.9em' }}>
                                    SKU: {producto?.Alias || ''}
                                </Card.Text>
                                <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '0.9em', color: '#16a34a', fontWeight: '600' }}>
                                    Garantía de {producto?.Garantia_meses || ''} meses
                                    <AiFillThunderbolt style={{ marginLeft: '8px', color: '#007bff', fontSize: '1.3em' }} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    ))
                )}

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <PaginationProducts currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </div>
            </Col>
        </Row>
    );
};

export default ProductosFiltradosContent;
