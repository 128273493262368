/* eslint-disable react/prop-types */
import React from 'react'
import './Index.css'
import { Button } from 'react-bootstrap'

const CardRelacionados = (props) => {
    return (
        <div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card custom-card">
                            <img src={props.Imagen} width={200} height={200} alt="Imagen" className="custom-image" style={{ padding: '20px' }} />
                            <div className="custom-content">
                                <p className="card-text">{props.Description}</p>
                                <Button 
                                    style={{
                                        width: '80%', // Ocupa el 80% del ancho
                                        margin: '0 auto', // Centra el botón
                                    }}>
                                    Ver más
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardRelacionados