import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export const useCategoryNavigation = () => {
    const [hoveredCategories, setHoveredCategories] = useState({});
    const navigate = useNavigate();

    const handleMouseEnter = (categoriaCodigo) => {
        setHoveredCategories((prevHovered) => ({
            ...prevHovered,
            [categoriaCodigo]: true,
        }));
    };

    const handleMouseLeave = (categoriaCodigo) => {
        setHoveredCategories((prevHovered) => ({
            ...prevHovered,
            [categoriaCodigo]: false,
        }));
    };

    const handleClick = (categoria) => {
        navigate(`/categorias/${categoria.Codigo}`);
    };

    const renderSubcategories = (subcategories) =>
        subcategories.map((subcategory) => (
            <div key={subcategory.Codigo}>
                {subcategory.Hijos.length > 0 ? (
                    <Dropdown
                        show={hoveredCategories[subcategory.Codigo]}
                        onMouseEnter={() => handleMouseEnter(subcategory.Codigo)}
                        onMouseLeave={() => handleMouseLeave(subcategory.Codigo)}
                    >
                        <Dropdown.Toggle
                            variant="dark"
                            id="navbarScrollingDropdownMarcas"
                            onClick={() => handleClick(subcategory)}
                        >
                            {subcategory.Descripcion.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {renderSubcategories(subcategory.Hijos)}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <Link
                        to={`/categorias/${subcategory.Codigo}`}
                        className="dropdown-item"
                        key={subcategory.Codigo}
                    >
                        {subcategory.Descripcion.toUpperCase()}
                    </Link>
                )}
            </div>
        ));

    return { handleMouseEnter, handleMouseLeave, handleClick, renderSubcategories, hoveredCategories };
};
