import React, { useState, useContext } from "react";
import { Container, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { globalContext } from "../Context/GlobalContext.js";
import { useTranslation } from "react-i18next";
import { condicionesRMA } from "../../config/config.js";
import { condicionesRMA_Argentina } from "../../config/config.js";

const isEmail = (str) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(str);
};

const isURL = (str) => {
  const urlPattern = /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([\\/\w .-]*)*\/?$/;
  return urlPattern.test(str);
};

export const RmaItemFallado = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { colorTheme } = useContext(globalContext);
  const country = process.env.REACT_APP_COUNTRY;

  const condiciones = country === "ARG" ? condicionesRMA_Argentina : condicionesRMA;

  const selectedBrand = condiciones.find((brand) => brand.value === selectedOption);

  let textColorTheme = colorTheme === "dark" ? "text-light" : "text-dark";

  const [t] = useTranslation("global");

  return (
    <>
      <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy' />

      <Container fluid className={`p-5 bg-${colorTheme}`}>
        <div className={`text-center py-4 text-${colorTheme === "dark" ? "light" : "dark"}`}>
          <h1 style={{ fontSize: "2rem" }}>{t("rma.titulo-condiciones-rma")}</h1>
        </div>

        {country === "ARG" && selectedBrand?.brandUrl && (
          <div className="text-center mb-4">
            <img
              src={process.env.PUBLIC_URL + selectedBrand.brandUrl}
              alt={`${selectedBrand.label} logo`}
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
        )}

        <Container className="d-flex justify-content-around align-items-start gap-3 fluid">
          <Col md={4}>
            <select
              className="form-select"
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{ fontSize: "1rem", padding: "8px" }}
            >
              <option>{t("rma.seleccione-marca")}</option>
              {condiciones.map((brand) => {
                if (brand.value === "SAMSUNG" && country === "UY") return null;
                return (
                  <option key={brand.value} value={brand.value}>
                    {brand.label}
                  </option>
                );
              })}
            </select>
          </Col>

          {selectedBrand && (
            <Col md={7} className="text-left">
              <p className={`text-${textColorTheme}`} style={{ fontSize: "1rem", lineHeight: "1.5" }}>
                <b>{t("rma.condiciones")} RMA {selectedBrand.label}</b>
                <br />
                <span style={{ fontSize: "1rem" }}>
                  {selectedBrand.conditions.warranty.duration &&
                    `${t("rma.garantia")} ${selectedBrand.conditions.warranty.duration}.`}
                  {/* {t("rma.garantia")}: {selectedBrand.conditions.warranty.duration}. */}
                  <br />
                  {selectedBrand.conditions.warranty.managedBy &&
                    `${t("rma.garantia-gestionada-solution-box")} ${selectedBrand.conditions.warranty.managedBy}.`}
                  {/* {selectedBrand.conditions.contact && (
                    <>
                      <br />
                      {"Contacto RMA"}:{" "}
                      {isEmail(selectedBrand.conditions.contact) ? (
                        <a href={`mailto:${selectedBrand.conditions.contact}`} style={{ fontSize: "1rem" }}>
                          {selectedBrand.conditions.contact}
                        </a>
                      ) : isURL(selectedBrand.conditions.contact) ? (
                        <a href={selectedBrand.conditions.contact} target="_blank" rel="noopener noreferrer" style={{ fontSize: "1rem" }}>
                          {selectedBrand.conditions.contact}
                        </a>
                      ) : (
                        <span>{selectedBrand.conditions.contact}</span>
                      )}
                    </>
                  )} */}
                  {selectedBrand.conditions.notes && (
                    <>
                      <br />
                      {selectedBrand.conditions.notes}
                    </>
                  )}
                  {selectedBrand.conditions.contact && (
                    <>
                      <br />
                      {"Contacto RMA"}:{" "}
                      {isEmail(selectedBrand.conditions.contact) ? (
                        <a href={`mailto:${selectedBrand.conditions.contact}`} style={{ fontSize: "1rem" }}>
                          {selectedBrand.conditions.contact}
                        </a>
                      ) : isURL(selectedBrand.conditions.contact) ? (
                        <a href={selectedBrand.conditions.contact} target="_blank" rel="noopener noreferrer" style={{ fontSize: "1rem" }}>
                          {selectedBrand.conditions.contact}
                        </a>
                      ) : (
                        <span>{selectedBrand.conditions.contact}</span>
                      )}
                    </>
                  )}
                </span>
              </p>
            </Col>
          )}
        </Container>

        {selectedBrand && (
          <div className="text-center mt-4">
            <Link to="/rma2">
              <Button variant="outline-primary" size="lg">
                {t("rma.btn-enviar-rma")}
              </Button>
            </Link>
          </div>
        )}
      </Container>
    </>
  );
};
