/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TbTruckDelivery } from 'react-icons/tb';

const TipoDeEntregaSelector = ({ info, tiposDeEntrega, country, handleEntregaChange }) => {
    const [t] = useTranslation("global");

    return (
        <div className="facturacion-entrega-checkout-estilos">
            <Form.Group controlId="entrega">
                <Form.Label>
                    <TbTruckDelivery size={'2.5em'} style={{ marginRight: '12px', color: 'red' }} />
                    {t("facturacion.opciones-entrega")}
                </Form.Label>
                <Form.Control
                    as="select"
                    value={info.entrega}
                    name="entrega"
                    onChange={handleEntregaChange}
                >
                    <option value="Retira personalmente">Seleccione...</option>
                    {tiposDeEntrega
                        .filter((entrega) => !(country === "CR" && entrega.Descripcion === "ENTREGA"))
                        .map((entrega) => (
                            <option key={entrega.Codigo} value={entrega.Descripcion}>
                                {entrega.Descripcion}
                            </option>
                        ))}
                </Form.Control>
            </Form.Group>
        </div>
    );
};

export default TipoDeEntregaSelector;
