import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import styled from 'styled-components'; // Importar styled-components
import './EstiloPages.css';
import { globalContext } from '../Context/GlobalContext';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Container)`
/*     flex-direction: column; /* Apilar elementos verticalmente */
    align-items: center; /* Centrar elementos horizontalmente */
    justify-content: space-around; /* Espacio alrededor de los elementos */  */
    height: 100%; /* Asegura que el contenedor ocupe el 100% de la altura disponible */
`;

const ImageLink = styled(Link)`
    display: flex; /* Usar flexbox para centrar la imagen */
    justify-content: center; /* Centrar horizontalmente */
    margin-bottom: 20px; /* Espacio inferior entre las imágenes */
`;

const StyledImage = styled.img`
    max-width: 90%; /* Asegurarse de que la imagen no exceda el ancho del contenedor */
    height: auto; /* Mantener la proporción de la imagen */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Suavizar la transición */

    /* Efecto de sombra para dar profundidad */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    &:hover {
        transform: translateY(-5px); /* Simula el efecto de botón presionado */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Aumentar sombra al hacer hover */
    }

    @media (max-width: 768px) {
    max-width: 100vw
    width: 100vw;
  }
`;


const Item = styled.div`
  display: flex;
      

  @media (max-width: 768px) {
    flex-direction: column; /* Apilar elementos verticalmente */
    align-items: center; /* Centrar elementos horizontalmente */
    justify-content: space-around; /* Espacio alrededor de los elementos */ 
  }
`;

const TitleSpanish = styled.h2`
    text-align: center; /* Centrar el texto */
    margin-bottom: 20px; /* Espacio inferior para separar del contenido */
    font-weight: bold; /* Hacer el texto en negrita */
    font-size: 300%;
`;

const TitleEnglish = styled.h2`
    text-align: center; /* Centrar el texto */
    margin-bottom: 20px; /* Espacio inferior para separar del contenido */
    font-weight: bold; /* Hacer el texto en negrita */
    font-size: 300%;
`;

const HighlightedText = styled.span`
    color: violet; /* Color violeta para resaltar "RMA" */
`;

export const SelectorRma = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; // Obtener el idioma actual

    // Definir las rutas de las imágenes según el idioma
    const rmaDefEsp = process.env.PUBLIC_URL + "/imagenes/RMA-DEF-ESP.png";
    const rmaDefEng = process.env.PUBLIC_URL + "/imagenes/RMA-DEF-ENG.png";
    const rmaDevEsp = process.env.PUBLIC_URL + "/imagenes/RMA-DEV-ESP.png";
    const rmaDevEng = process.env.PUBLIC_URL + "/imagenes/RMA-DEV-ENG.png";
    const [t] = useTranslation("global");

    return (
        <div>
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy' />
            <StyledContainer fluid>
                {currentLanguage === 'es' ? (
                    <TitleSpanish>
                        Seleccione el motivo de <HighlightedText>RMA</HighlightedText>
                    </TitleSpanish>
                ) : (
                    <TitleEnglish>
                        Please, select <HighlightedText>RMA</HighlightedText> reason
                    </TitleEnglish>
                )}


                {/* Envolver imágenes en enlaces */}
                <Item>
                  <ImageLink to={currentLanguage === 'en' ? "/rma3" : "/rma3"}>
                      <StyledImage
                          src={currentLanguage === 'en' ? rmaDefEng : rmaDefEsp}
                          alt="RMA Defectuoso"
                      />
                  </ImageLink>
                  <ImageLink to={currentLanguage === 'en' ? "/rma2" : "/rma2"}>
                      <StyledImage
                          src={currentLanguage === 'en' ? rmaDevEng : rmaDevEsp}
                          alt="RMA Devolucion"
                      />
                  </ImageLink>
                </Item>
            </StyledContainer>
        </div>
    );
};
