/* eslint-disable react/prop-types */
import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationProducts = ({ currentPage, totalPages, handlePageChange }) => {
    return (
        <Pagination>
            <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />

            {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </Pagination.Item>
            ))}

            <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        </Pagination>
    );
};

export default PaginationProducts;
