import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from '../Data/LocalStorage';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ButtonTranslate.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ButtonTranslate = () => {
    // eslint-disable-next-line no-unused-vars
    const [t, i18n] = useTranslation("global");
    const [translatedTo, setTranslatedTo] = LocalStorage("translatedTo", "es");
    const [currentFlag, setCurrentFlag] = useState(translatedTo);

    const flagStyle = {
        cursor: "pointer",
        borderRadius: "50%",
        border: "1px solid white",
        transition: "transform 0.2s ease-out",
        marginRight: "0.5em",
    };

    useEffect(() => {
        i18n.changeLanguage(translatedTo);
        setCurrentFlag(translatedTo);
    }, [i18n, translatedTo]);

    const notify = (lng) => toast.success(
        lng === 'es' ? "Cambiando a español" : "Switched to English", {
        position: "top-center",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    }
    );

    const handleSelect = (lng) => {
        setTranslatedTo(lng);
        notify(lng);
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Dropdown>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-flag">{currentFlag === 'es' ? 'Español' : 'Inglés'}</Tooltip>}
                >
                    <Dropdown.Toggle variant="transparent" style={{ border: 'none', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                        <img
                            width={25}
                            height={25}
                            src={process.env.PUBLIC_URL + `/assets/Banderas/${currentFlag === 'es' ? 'spain.png' : 'estadosunidos.png'}`}
                            alt={currentFlag.toUpperCase()}
                            style={flagStyle}
                        />
                        <span style={{ marginLeft: '0.5em', color: '#fff' }}>
                            {currentFlag === 'es' ? 'Español (ES)' : 'Inglés (EN)'}
                        </span>
                    </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelect("es")}>
                        <img
                            width={20}
                            height={20}
                            src={process.env.PUBLIC_URL + "/assets/Banderas/spain.png"}
                            alt="ES"
                            style={{ marginRight: '0.5em' }}
                        />
                        Español (ES)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSelect("en")}>
                        <img
                            width={20}
                            height={20}
                            src={process.env.PUBLIC_URL + "/assets/Banderas/estadosunidos.png"}
                            alt="EN"
                            style={{ marginRight: '0.5em' }}
                        />
                        Inglés (EN)
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default ButtonTranslate;
