import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { LocalStorage } from "../Data/LocalStorage";
import { useNavigate } from "react-router-dom";
import { authContext } from "./AuthContext";
export const globalContext = createContext();

const { Provider } = globalContext;

const GlobalContext = (props) => {
    const country = process.env.REACT_APP_COUNTRY;
    const navigate = useNavigate()
    const [colorTheme, setColorTheme] = LocalStorage("darkMode", "light");
    const [grid, setGrid] = useState(country === "UY" ? true : false);
    const [darkMode, setDarkMode] = useState(
        colorTheme === "light" ? false : true
    );
    const [info, setInfo] = useState({});
    const [tiposDeEntrega, setTiposDeEntrega] = useState([]);
    const [condPago, setCondPago] = useState([]);
    const [pago, setPago] = useState(info.pago || 'efectivo');
    const [defaultValue, setDefaultValue] = useState('Retira personalmente');
    const [esPickup, setEsPickup] = useState(false);
    const [esPrePay, setEsPrepay] = useState(false)
    const [isEntregaSelected, setIsEntregaSelected] = useState(false);

    // Agrego variables para gestionar el buscador
    const [searchInput, setSearchInput] = useState('');
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [suggestedBrands, setSuggestedBrands] = useState([]);
    const [suggestedCategories, setSuggestedCategories] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const [suggestedSKUs, setSuggestedSKUs] = useState([]);
    const [categoriaLoaded, setCategoriaLoaded] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);

    // DNI files -- Persona autorizada a retirar 
    const [authorizedPerson, setAuthorizedPerson] = useState([]);
    const [selectedAuthorizedPerson, setSelectedAuthorizedPerson] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    // FEATURE DIRECCION DE ENTREGA
    const [isCompletedEntregaForm, setIsCompletedEntregaForm] = useState(false);
    const { user } = useContext(authContext);
    const [showFormCambiarDireccion, setShowCambiarDireccion] = useState(false)

    // GESTIONO LA DIRECCION DE ENTREGA

    const [direccionEntrega, setDireccionEntrega] = useState({
        Direccion: user?.Domicilio_entrega?.Domicilio || "",
        Localidad: user?.Domicilio_entrega?.Localidad || "",
        Codigo_Postal: user?.Domicilio_entrega?.Codigo_postal || "",
        Provincia: {
            Codigo: user?.Domicilio_entrega?.Provincia?.Codigo || "",
            Descripcion: user?.Domicilio_entrega?.Provincia?.Descripcion || ""
        },
        Pais: "AR"
    });

    const [direccionForm, setDireccionForm] = useState({
        direccion: direccionEntrega.Direccion,
        localidad: direccionEntrega.Localidad,
        codigoPostal: direccionEntrega.Codigo_Postal,
        provincia: direccionEntrega.Provincia,
        pais: direccionEntrega.Pais,
    });


    useEffect(() => {
        setDireccionEntrega({
            Direccion: user?.Domicilio_entrega?.Domicilio || "",
            Localidad: user?.Domicilio_entrega?.Localidad || "",
            Codigo_Postal: user?.Domicilio_entrega?.Codigo_postal || "",
            Provincia: {
                Codigo: user?.Domicilio_entrega?.Provincia?.Codigo || "",
                Descripcion: user?.Domicilio_entrega?.Provincia?.Descripcion || ""
            },
            Pais: "AR"
        });
    }, [user]);


    const handleDireccionChange = (e) => {
        const { name, value, selectedOptions } = e.target;
        const selectedOption = selectedOptions ? selectedOptions[0] : null;

        setDireccionForm((prevState) => ({
            ...prevState,
            [name]: selectedOption
                ? {
                    Codigo: selectedOption.dataset.codigo,
                    Descripcion: selectedOption.text,
                }
                : value,
        }));
    };

    const handleToggleCambiarDireccion = () => {
        setShowCambiarDireccion(!showFormCambiarDireccion);
    };


    const handleDisplayGrid = () => {
        setGrid(true);
    };

    const handleDisplayList = () => {
        setGrid(false);
    };

    const infoInputs = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInfo(values => ({ ...values, [name]: value }))
    }

    // Handler para options de la entrega 
    const handleEntregaChange = useCallback((e) => {
        const value = e.target.value;
        const selectedEntrega = tiposDeEntrega.find(entrega => entrega.Descripcion === value);

        if (!selectedEntrega) {

            setInfo(values => ({
                ...values,
                entrega: value,
                Descripcion: "",
                Codigo: "",
            }));

            setIsEntregaSelected(false);
            setDefaultValue(value);


            return;
        }

        setInfo(values => ({
            ...values,
            entrega: value,
            Descripcion: selectedEntrega.Descripcion,
            Codigo: selectedEntrega.Codigo,
        }));

        setIsEntregaSelected(true);
        setDefaultValue(value);

        if (country === "USA" && selectedEntrega.Codigo === "P") {
            setEsPickup(true);
        } else {
            setEsPickup(false);
        }
    }, [tiposDeEntrega, country]);




    // Buscador
    const closeSuggestions = () => {
        setSuggestedProducts([]);
        setSuggestedBrands([]);
        setSuggestedCategories([]);
        setSuggestedSKUs([]);
        setSearchInput('')
    };

    const handleSuggestionClick = (suggestion, type) => {
        setSearchInput(suggestion);
        if (type === 'producto') {
            navigateToProduct(suggestion);
        } else if (type === 'marca') {
            navigateToBrand(suggestion);
        } else if (type === 'categoria') {
            navigateToCategory(suggestion)
        } else if (type === 'sku') {
            navigateToSKU(suggestion);
        }
    };

    const navigateToProduct = (productId) => {
        navigate(`/detalle?sku=${productId}`);
        setSearchInput('');
    };

    const navigateToBrand = (brandCode) => {
        navigate(`/marcas/${brandCode}`);
        setSearchInput('');
    };

    const navigateToCategory = (categoryCode) => {
        navigate(`/categorias/${categoryCode}`);
        setSearchInput('');
    }

    const navigateToSKU = (sku) => {
        navigate(`/detalle?sku=${sku}`);
        setSearchInput('');
    }


    const contextValue = {
        colorTheme,
        setColorTheme,
        darkMode,
        setDarkMode,
        grid,
        setGrid,
        handleDisplayGrid,
        handleDisplayList,
        info,
        setInfo,
        tiposDeEntrega,
        setTiposDeEntrega,
        condPago,
        setCondPago,
        pago,
        setPago,
        infoInputs,
        defaultValue,
        setDefaultValue,
        esPickup,
        setEsPickup,
        handleEntregaChange,
        esPrePay,
        setEsPrepay,
        isEntregaSelected,
        setIsEntregaSelected,
        // Buscador
        searchInput,
        setSearchInput,
        suggestedProducts,
        setSuggestedProducts,
        suggestedBrands,
        setSuggestedBrands,
        suggestedCategories,
        setSuggestedCategories,
        categoria,
        setCategoria,
        suggestedSKUs,
        setSuggestedSKUs,
        categoriaLoaded,
        setCategoriaLoaded,
        userInteracted,
        setUserInteracted,
        closeSuggestions,
        handleSuggestionClick,
        authorizedPerson,
        setAuthorizedPerson,
        selectedAuthorizedPerson,
        setSelectedAuthorizedPerson,
        isUploading,
        setIsUploading,
        direccionEntrega,
        setDireccionEntrega,
        isCompletedEntregaForm,
        setIsCompletedEntregaForm,
        direccionForm,
        setDireccionForm,
        handleDireccionChange,
        showFormCambiarDireccion,
        setShowCambiarDireccion,
        handleToggleCambiarDireccion
    };

    // eslint-disable-next-line react/prop-types
    return <Provider value={contextValue}>{props.children}</Provider>;
};

export default GlobalContext;
