/* eslint-disable react/prop-types */
import './Table.css';
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddItem from "../Carrito/AddItem";
import sinimagen from './sinimagen.gif';
import React, { useContext } from 'react';
import { authContext } from '../Context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Tables(props) {
  const navigate = useNavigate()
  // const articulosXMarca = props.articulo;
  const articulosXMarca = props.articulosXMarca
  const { token } = useContext(authContext);

  console.log(articulosXMarca, "articulos por marca")

  const [t] = useTranslation("global")

  return (
    <>
      <div className="table-container">
        <Table hover variant='light'>
          <tbody>
            {articulosXMarca.map((articulo, idx) => {
              return (
                <tr key={idx}>
                  <td className="fondoBlanco">
                    <img src={articulo.Imagenes ? process.env.PUBLIC_URL + `/articulos/thumbs/${encodeURIComponent(String(articulo.Imagenes.split(',')[0]))}` : sinimagen}
                      alt="sbox"
                    />
                  </td>
                  <td>
                    <h6 className='table-name-product' onClick={() => navigate(`/detalle?sku=${articulo.Alias}`)}>{articulo.Nombre}</h6>
                    <p>
                      <span className="numero-de-parte">SKU: {articulo.Alias + " "}</span>

                      <span className='colorValor'>
                        {(articulo?.Stock > 0 && articulo?.Precio !== undefined) && (
                          <>
                            || Stock: {articulo.Stock}
                            {articulo?.Moneda_Signo && ` || Valor: ${articulo.Moneda_Signo} ${articulo.Precio}`}
                          </>
                        )}
                      </span>

                      {/* ||
                       {articulo.Precio === undefined || pais === "USA" ? '' : `* Los precios no incluyen ${pais === "PA" ? "ITBMS" : "IVA"}`} */}
                    </p>
                  </td>
                  <td className="table-botonera">
                    <div className="button-container">
                      <div className="button-contenedor-principal">
                        <div className={`price-container`}></div>
                        <div className="logged-buttons">
                          <div className="ver-producto-container">
                            {!token ?
                              <Link
                                to={`/detalle?sku=${articulo.Alias}`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '0.5rem',
                                  padding: '0.25rem 0.5rem',
                                  fontSize: '0.75rem',
                                  justifyContent: 'center',
                                  minWidth: '120px',
                                  textDecoration: 'none', // Para quitar el subrayado del enlace
                                  color: 'dc3545', // Color del texto (rojo)
                                  border: '1px solid #dc3545', // Borde rojo
                                  borderRadius: '0.25rem', // Bordes redondeados
                                  backgroundColor: 'transparent', // Fondo transparente
                                  transition: 'background-color 0.2s, color 0.2s',
                                }}
                                onClick={(event) => {

                                  if (event.button === 1) { // Click medio
                                    window.open(`/detalle?sku=${articulo.Alias}`, "_blank");
                                    event.preventDefault(); // Evita la navegación del Link
                                  }
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor = '#dc3545'; // Fondo rojo al pasar el mouse
                                  e.currentTarget.style.color = 'white'; // Texto blanco al pasar el mouse
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor = 'transparent'; // Fondo transparente al salir
                                  e.currentTarget.style.color = '#dc3545'; // Texto rojo al salir
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  style={{ width: '14px', height: '14px' }}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                {t("producto.ver-producto")}
                              </Link>
                              :
                              <div className='d-flex justify-content-evenly gap-3'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Link
                                    to={`/detalle?sku=${articulo.Alias}`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '0.5rem',
                                      padding: '0.25rem 0.5rem',
                                      fontSize: '0.75rem',
                                      justifyContent: 'center',
                                      minWidth: '120px',
                                      textDecoration: 'none', // Para quitar el subrayado del enlace
                                      color: 'dc3545', // Color del texto (rojo)
                                      border: '1px solid #dc3545', // Borde rojo
                                      borderRadius: '0.25rem', // Bordes redondeados
                                      backgroundColor: 'transparent', // Fondo transparente
                                      transition: 'background-color 0.2s, color 0.2s',
                                    }}
                                    onClick={(event) => {

                                      if (event.button === 1) { // Click medio
                                        window.open(`/detalle?sku=${articulo.Alias}`, "_blank");
                                        event.preventDefault(); // Evita la navegación del Link
                                      }
                                    }}
                                    onMouseEnter={(e) => {
                                      e.currentTarget.style.backgroundColor = '#dc3545'; // Fondo rojo al pasar el mouse
                                      e.currentTarget.style.color = 'white'; // Texto blanco al pasar el mouse
                                    }}
                                    onMouseLeave={(e) => {
                                      e.currentTarget.style.backgroundColor = 'transparent'; // Fondo transparente al salir
                                      e.currentTarget.style.color = '#dc3545'; // Texto rojo al salir
                                    }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      style={{ width: '14px', height: '14px' }}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                    {t("producto.ver-producto")}
                                  </Link>
                                </div>
                                <AddItem producto={articulo} />
                              </div>

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

