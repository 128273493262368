/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import styles from "./Header.module.css";
import { data } from "../../../config/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { authContext } from "../../Context/AuthContext";
import CartWidget from "../../Carrito/CartWidget";
import Buscador from "../../Buscador/Buscador";
import ButtonTranslate from "../../ButtonTranslate/ButtonTranslate";
import BuscadorNuevo from "../../Buscador/BuscadorNuevo";

const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const { user, desloguearse2 } = useContext(authContext);
    const [t] = useTranslation("global");
    const country = process.env.REACT_APP_COUNTRY;
    // eslint-disable-next-line no-unused-vars
    const [tasaDeCambio, setTasaDeCambio] = useState(null);



    const eleccionRegistro = () => {
        Swal.fire({
            html: `<h3><b>${t("login.exclusivo-h3")}</b></h3> <br> <h5>${t("login.exclusivo-h5")}</h5>`,
            showCancelButton: false,
            confirmButtonText: `${t("login.alta-web")}`,
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(`/registroUsuario`);
            } else {
                navigate(`/contacto`);
            }
        });
    };

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    let rucPais = ""

    if (user && country === "PY") {
        rucPais =
            <span>{`${user?.Nombre} ${user?.Apellido} (RUC: ${user?.Cuit})`}</span>
    } else {
        rucPais =
            <span>{`${user?.Nombre} ${user?.Apellido} (CUIT: ${user?.Cuit})`}</span>
    }

    return (
        <header className={styles.headerNuevo}>
            <Container fluid>
                <Row className={`${styles.rowNuevo} align-items-center justify-content-around`}>
                    <Col xs="auto">
                        <Link to={"/"}>
                            <img
                                src={data.url_brand}
                                alt="Logo"
                                height="auto"
                                className={styles.logoNuevo}
                            />
                        </Link>
                    </Col>
                    <Col xs={9} md={5} className="mobile-search-col">
                        <BuscadorNuevo />
                    </Col>


                    <Col xs="auto" className={`d-flex align-items-center ${styles.accountNuevo}`}>
                        <ButtonTranslate />
                        <div className="">
                            <CartWidget />
                        </div>
                        <div
                            onClick={toggleDropdown} // Agrega este evento para "Mi cuenta"
                            ref={dropdownRef}
                            className="position-relative"
                        >
                            <FaUserCircle
                                size={30}
                                className={user ? styles.iconWithUser : styles.iconWithoutUser}
                            />

                            <Dropdown show={dropdownOpen} className={styles.dropdownContainer}>
                                <Dropdown.Menu
                                    className={`${styles.dropdownMenu} mt-2`}
                                    onClick={() => setDropdownOpen(false)}
                                >
                                    {user ? (
                                        <>
                                            <Dropdown.Item onClick={() => navigate('/profile')}>
                                                <i className="fa-solid fa-user"></i> Ver mi perfil
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => desloguearse2('CS')}>
                                                <i className="fa-solid fa-right-from-bracket"></i> Cerrar sesión
                                            </Dropdown.Item>
                                        </>
                                    ) : (
                                        <>
                                            <Dropdown.Item onClick={() => navigate("/login")}>
                                                <i className="fa-solid fa-user"></i> Iniciar sesion
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={eleccionRegistro}>
                                                <i className="fa-solid fa-right-from-bracket"></i> Registrarme
                                            </Dropdown.Item>
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div
                            onClick={toggleDropdown}
                            className={`${styles.accountTextNuevo} ms-2`}
                        >
                            {user ? (
                                <>
                                    <div>{rucPais}</div>
                                    {user.Cliente && (
                                        <div>
                                            <div style={{ textAlign: 'center' }}>Numero de cliente: {user.Cliente}</div>
                                            {user.Cotizacion ? (
                                                <div style={{ textAlign: 'center' }}>{`1 USD = ${user.Cotizacion}`}</div>
                                            ) : (
                                                country === "CR" &&
                                                tasaDeCambio && (
                                                    <span>{`1 USD = ₡ ${tasaDeCambio}`}</span>
                                                )
                                            )}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className={user ? styles.iconWithUser : styles.iconWithoutUser}>Mi cuenta</div>
                            )}
                        </div>
                    </Col>

                </Row>
            </Container>
        </header>
    );
};

export default Header;
