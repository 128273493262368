import { useEffect, useState } from 'react';
import { getCategoriasService } from '../../../services/Buscador';

export const useGetCategorias = (query) => {
    const [categorias, setCategorias] = useState([]);
    const [loadingCategorias, setLoadingCategorias] = useState(false);
    const [errorCategorias, setErrorCategorias] = useState(null);

    const fetchCategorias = async (retryCount = 0) => {
        setLoadingCategorias(true);
        try {
            const storedCategorias = localStorage.getItem('categorias-stored');
            if (storedCategorias) {
                setCategorias(JSON.parse(storedCategorias));
                return;
            }
            const categoriasData = await getCategoriasService();
            setCategorias(categoriasData);
            localStorage.setItem('categorias-stored', JSON.stringify(categoriasData));
            return categorias;
        } catch (error) {
            if (retryCount < 3) {
                fetchCategorias(retryCount + 1);
            } else {
                console.error('Max retries reached, unable to fetch categories', error);
                setErrorCategorias('No se pudieron obtener las categorías.');
            }
        } finally {
            setLoadingCategorias(false);
        }
    };

    useEffect(() => {
        fetchCategorias();
    }, [query]);

    return { categorias, loadingCategorias, errorCategorias };
};
