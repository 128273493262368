/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DireccionEntregaContainer = ({
    country,
    info,
    direccionEntrega,
    user,
    showFormCambiarDireccion,
    handleToggleCambiarDireccion
}) => {
    const [t] = useTranslation("global");

    return (
        <>
            {country === "ARG" &&
                info.Codigo !== "1" &&
                info.Codigo !== "2" &&
                info.Codigo !== undefined && (
                    <div style={{ flex: '1', marginRight: '20px' }}>
                        <h3 style={{ color: '#475569' }}>Direccion de entrega:</h3>
                        <h6 style={{ color: '#9ca3af' }}>{t("facturacion.podra-coordinar")}</h6>
                        <br />
                        <h6 style={{ color: '#9ca3af' }}>
                            <b>Direccion:</b> {direccionEntrega.Direccion}
                        </h6>
                        <h6 style={{ color: '#9ca3af' }}>
                            <b>Codigo Postal:</b> {direccionEntrega.Codigo_Postal}
                        </h6>
                        <h6 style={{ color: '#9ca3af' }}>
                            <b>Localidad:</b>
                            {info.entrega === "ENTREGA"
                                ? user.Domicilio_entrega.Localidad
                                : user.Domicilio_facturacion.Localidad}
                        </h6>
                        <h6 style={{ color: '#9ca3af' }}>
                            <b>Pais:</b>
                            {info.entrega === "ENTREGA"
                                ? user.Domicilio_entrega.Pais
                                : user.Domicilio_facturacion.Pais}
                        </h6>
                        <h6 style={{ color: '#9ca3af' }}>
                            <b>Provincia:</b>
                            {info.entrega === "ENTREGA"
                                ? `${user.Domicilio_entrega.Provincia?.Descripcion} (${user.Domicilio_entrega.Provincia?.Codigo})`
                                : `${user.Domicilio_entrega.Provincia?.Descripcion} (${user.Domicilio_entrega.Provincia?.Codigo})`}
                        </h6>
                        {!showFormCambiarDireccion && (
                            <Button
                                variant="outline-success"
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleToggleCambiarDireccion}
                            >
                                Actualizar direccion de entrega
                            </Button>
                        )}
                    </div>
                )}
        </>
    );
};

export default DireccionEntregaContainer;
