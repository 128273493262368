/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "react-bootstrap";
import { Description } from "./styles";

const Info = ({ data: marca }) => {
  // const country = process.env.REACT_APP_COUNTRY;

  const renderDescriptionArray = (descripcionArray) => {
    if (!Array.isArray(descripcionArray) || descripcionArray.length === 0) {
      return "Descripción del producto no disponible";
    }

    return (
      <ul className="list-disc pl-5">
        {descripcionArray
          .filter((item) => item.trim() !== "")
          .map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
      </ul>
    );
  };

  // Funcion para formatear y renderizar Descripcion_corta (en caso de no ser ARG)
  // const formatDescription = (description) => {
  //   return { __html: description.replace(/\n/g, "<br />") };
  // };

  const handleDownload = () => {
    const fileName = marca.Manual_Tecnico;

    if (fileName) {
      const fileUrl = `${process.env.PUBLIC_URL}/articulos/${encodeURIComponent(fileName)}`;

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;

      link.target = "_blank";
      link.click();
    } else {
      alert("El archivo no está disponible.");
    }
  };

  return (
    <Description>
      <div>
        <span>{marca.Nombre}</span>
        <hr />
        <br />
        {/* {country === "ARG" ? (
          marca.descripcionArray ? (
            <div>{renderDescriptionArray(marca.descripcionArray)}</div>
          ) : (
            <p>Descripción del producto no disponible</p>
          )
        ) : (
          marca.Descripcion_corta ? (
            <div dangerouslySetInnerHTML={formatDescription(marca.Descripcion_corta)} />
          ) : (
            <p>Descripción del producto no disponible</p>
          )
        )} */}
        {marca.descripcionArray ? (
          <div>{renderDescriptionArray(marca.descripcionArray)}</div>
        ) : (
          <p>Descripción del producto no disponible</p>
        )}
        <br />

        {/* Verificar si marca.Manual_Tecnico existe y no es null */}
        {marca.Manual_Tecnico && marca.Manual_Tecnico !== null && (
          <div className="text-center mt-4">
            <Button
              variant="outline-primary"
              className="rounded-pill"
              onClick={handleDownload}
            >
              Descargar Manual Técnico
            </Button>
          </div>
        )}
      </div>
    </Description>
  );
};

export default Info;



// /* eslint-disable react/prop-types */
// import React from "react";
// import { Description } from "./styles";

// const Info = ({ data: marca }) => {
//   const formatDescription = (description) => {
//     return { __html: description.replace(/\n/g, "<br />") };
//   };

//   return (
//     <Description>
//       <div>
//         <p>
//           <span>{marca.Nombre} </span>
//           <hr />
//           <br />
//           {marca.Descripcion_corta ? <div dangerouslySetInnerHTML={formatDescription(marca.Descripcion_corta)} /> : "Descripción del producto no disponible"}
//           <br />
//         </p>
//       </div>
//     </Description>
//   );
// };

// export default Info;
