/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

const DatosFacturacionUser = ({ user, rucPais }) => {
    const [t] = useTranslation("global");

    return (
        <div className="facturacion-datos-checkout-estilos">
            <h3>{t("facturacion.datos")}</h3>
            <h6>
                <b>{t("facturacion.nombre")}:</b> {user.Nombre}
            </h6>
            <h6>
                <b>{t("facturacion.apellido")}:</b> {user.Apellido}
            </h6>
            <h6>
                <b>{t("formulario.label-2")}:</b> {user.Email}
            </h6>
            <h6>
                <b>{rucPais}:</b> {user.Cuit}
            </h6>
        </div>
    );
};

export default DatosFacturacionUser;
