/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

const DireccionFacturacionContainer = ({ country, info, user }) => {
    const [t] = useTranslation("global");

    return (
        <div style={{ flex: '1', marginRight: '20px' }}>
            <h3 style={{ color: '#475569' }}>
                Direccion declarada:
            </h3>
            <h6 style={{ color: '#9ca3af' }}>{t("facturacion.podra-coordinar")}</h6>
            <br />
            <h6 style={{ color: '#9ca3af' }}>
                <b>{t("formulario.direccion")}:</b> {info.entrega === "ENTREGA" ? user.Domicilio_entrega.Domicilio : user.Domicilio_facturacion.Domicilio}
            </h6>
            <h6 style={{ color: '#9ca3af' }}>
                <b>{t("facturacion.codigo-postal")}:</b> {info.entrega === "ENTREGA" ? user.Domicilio_entrega.Codigo_postal : user.Domicilio_facturacion.Codigo_postal || 'No informado'}
            </h6>
            <h6 style={{ color: '#9ca3af' }}>
                <b>{t("facturacion.localidad")}:</b> {info.entrega === "ENTREGA" ? user.Domicilio_entrega.Localidad : user.Domicilio_facturacion.Localidad}
            </h6>
            <h6 style={{ color: '#9ca3af' }}>
                <b>{t("facturacion.pais")}:</b> {info.entrega === "ENTREGA" ? user.Domicilio_entrega.Pais : user.Domicilio_facturacion.Pais}
            </h6>
        </div>
    );
};

export default DireccionFacturacionContainer;
