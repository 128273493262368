import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { marcasContext } from "../../components/Context/MarcasContext";

export const useFetchMarcas = (apiProductos) => {
    const [marcasMapeadas, setMarcasMapeadas] = useState([]);
    const { setCodigoMarca } = useContext(marcasContext);

    useEffect(() => {
        const fetchMarcas = async () => {
            try {
                const response = await axios(`${apiProductos}/marcas`);
                setMarcasMapeadas(response.data);
                setCodigoMarca(response.data);
            } catch (err) {
                console.error("Error fetching marcas:", err);
            }
        };

        fetchMarcas();
    }, []);

    return { marcasMapeadas };
};
