/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';
import { GoPerson } from 'react-icons/go';
import { MdFileUpload } from 'react-icons/md';

const AutorizadoForm = ({ authorizedPerson, handleRetiraChange, setShowModal, selectedAuthorizedPerson }) => {
    return (
        <div style={{ flex: '1' }}>
            <>
                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#475569' }}>
                    <GoPerson size={'2.5em'} style={{ marginRight: '12px', color: 'red' }} />
                    Seleccione autorizado a retirar
                </Form.Label>
                <Form.Control as="select" onChange={handleRetiraChange}>
                    <option>Seleccione...</option>
                    {authorizedPerson.length === 0 ? null : (
                        authorizedPerson.map((file, index) => (
                            <option key={index} value={file.nombre}>
                                {file.nombre} {file.apellido}, DNI: {file.nroDocumento}
                            </option>
                        ))
                    )}
                </Form.Control>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '20px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    textAlign: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        border: '2px solid #dcdcdc',
                        padding: '8px',
                        backgroundColor: 'transparent',
                        width: 'fit-content',
                        height: 'fit-content',
                    }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}>
                        <MdFileUpload onClick={() => setShowModal(true)} size={'1.5em'} style={{ color: 'red' }} />
                    </div>
                    <span style={{ marginTop: '8px', fontWeight: 'bold' }}>Cargar nuevo autorizado</span>
                    {!selectedAuthorizedPerson && (
                        <span style={{
                            marginTop: '12px',
                            fontSize: '0.900rem',
                            color: '#dc3545',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'block',
                        }}>
                            * Debes seleccionar un autorizado para avanzar o puedes autorizar una nueva persona.
                        </span>
                    )}
                    <span style={{
                        marginTop: '12px',
                        fontSize: '0.875rem',
                        color: '#6c757d',
                        textAlign: 'center',
                        fontStyle: 'italic',
                        display: 'block',
                    }}>
                        * Los formatos permitidos son .png, .jpg, .jpeg, .pdf
                    </span>
                </div>
            </>
        </div>
    );
};

export default AutorizadoForm;
