import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchProvincias = (country) => {
    const [provincias, setProvincias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (country === 'ARG') {
            const fetchProvincias = async () => {
                try {
                    setLoading(true);
                    const res = await axios.get(`${process.env.REACT_APP_API_CLIENTE}/provincias`);
                    setProvincias(res.data);
                } catch (error) {
                    setError(error);
                    console.error("Error al obtener las provincias:", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchProvincias();
        }
    }, [country]);

    return { provincias, loading, error };
};
