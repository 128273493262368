/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const PopUp = ({ show, handleClose, title }) => {
    const handleImageClick = () => {
        window.open('https://www.solutionbox.com.ar/marcas/LEC', '_blank');
    };

    const handleVisitClick = () => {
        handleClose();
        handleImageClick();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img
                    src={process.env.PUBLIC_URL + "/imagenes/Popups/popUpArg.jpg"}
                    alt="Banner"
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={handleImageClick}
                />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'center' }}>
                <Button
                    variant="danger"
                    onClick={handleVisitClick}
                    style={{ borderRadius: '20px', width: '200px' }}
                >
                    Ver ofertas
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopUp;
