/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import './Checkout.css';
import { globalContext } from '../Context/GlobalContext';
import { getTiposDeEntrega } from '../../services/CondicionesDePagoYEntrega/getTiposDeEntrega';
import UploadDNIModal from '../shared/UploadDNI/UploadDNIModal';
import { updateDNIService } from '../../services/DNI_Feature';
import { toast } from 'react-toastify';
import { authContext } from '../Context/AuthContext';
import useFetchAuthorizedPerson from '../../utilities/hooks/useFetchAuthorizedPerson';
import { useFetchProvincias } from './../../utilities/hooks/useFetchProvincias';
import DatosFacturacionUser from './../shared/EntregaFeature/DatosFacturacionUser';
import TipoDeEntregaSelector from './../shared/EntregaFeature/TipoDeEntregaSelector';
import DireccionFacturacionContainer from './../shared/EntregaFeature/DireccionFacturacionContainer';
import DireccionEntregaContainer from './../shared/EntregaFeature/DireccionEntregaContainer';
import AutorizadoForm from './../shared/Forms/AutorizadoForm';
import DirectionForm from './../shared/Forms/DirectionForm';

export const Facturacion = ({
  user,
  enviarOrden,
  handleActualizarEntrega,
}) => {
  const {
    info,
    tiposDeEntrega,
    setTiposDeEntrega,
    infoInputs,
    handleEntregaChange,
    defaultValue,
    authorizedPerson,
    setAuthorizedPerson,
    selectedAuthorizedPerson,
    setSelectedAuthorizedPerson,
    direccionEntrega,
    setDireccionEntrega,
    setIsCompletedEntregaForm,
    direccionForm,
    handleDireccionChange,
    showFormCambiarDireccion,
    handleToggleCambiarDireccion
  } = useContext(globalContext);

  const country = process.env.REACT_APP_COUNTRY;
  let rucPais = country === "PY" ? "RUC" : "DNI/CUIL";
  const { token } = useContext(authContext)
  const { provincias } = useFetchProvincias(country);

  const [showModal, setShowModal] = useState(false);
  const { fetchAuthorizedPerson } = useFetchAuthorizedPerson()

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);


  const updateAuthorizedPerson = async (id, updatedData) => {
    try {
      await updateDNIService(id, updatedData, token);
      const updatedAuthorizedPerson = authorizedPerson.map(person =>
        person.id === id ? { ...person, ...updatedData } : person
      );
      setAuthorizedPerson(updatedAuthorizedPerson);
      await fetchAuthorizedPerson();

      toast.success("Autorizado actualizado correctamente", {
        position: "bottom-right"
      });
    } catch (error) {
      console.error('Error al actualizar el autorizado:', error);
      toast.error("Error al actualizar el autorizado", {
        position: "bottom-right"
      });
    }
  };

  useEffect(() => {
    infoInputs({ target: { name: 'entrega', value: defaultValue } });
  }, [defaultValue]);

  const fetchTiposDeEntrega = async () => {
    const storedTiposDeEntrega = localStorage.getItem('tiposDeEntrega');
    if (storedTiposDeEntrega) {
      setTiposDeEntrega(JSON.parse(storedTiposDeEntrega));
    } else {
      try {
        const data = await getTiposDeEntrega();
        setTiposDeEntrega(data);
        localStorage.setItem('tiposDeEntrega', JSON.stringify(data));
      } catch (error) {
        console.log(error)
      }
    }
  };

  useEffect(() => {
    fetchTiposDeEntrega();
  }, []);

  useEffect(() => {
    handleActualizarEntrega();
  }, [info.entrega, info.pago, direccionEntrega]);

  // Handler para el cambio en el selector
  // eslint-disable-next-line no-unused-vars
  const handleRetiraChange = async (event) => {
    const { value } = event.target;
    const selected = authorizedPerson.find(person => person.nombre === value);
    setSelectedAuthorizedPerson(selected || null);

    if (selected) {
      const updatedData = { autorizado: "true" };
      await updateAuthorizedPerson(selected.id, updatedData);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };


  useEffect(() => {
    if (selectedAuthorizedPerson) {
      console.log("Autorizado seleccionado:", selectedAuthorizedPerson);
    }
  }, [selectedAuthorizedPerson]);

  // La solicitud la depsego solo en Argentina
  useEffect(() => {
    if (country === "ARG" && (info.Codigo === "1" || info.Codigo === "2")) {
      if (!authorizedPerson || authorizedPerson.length === 0) {
        fetchAuthorizedPerson();
      }
    }
  }, [info.entrega]); // Lo ejecuto cuando cambie info.entrega para evirtar re renders

  const handleSubmitDireccion = (e) => {
    e.preventDefault();

    if (
      !direccionForm.direccion ||
      !direccionForm.codigoPostal ||
      !direccionForm.localidad ||
      !direccionForm.provincia.Descripcion ||
      !direccionForm.pais
    ) {
      toast.error("Todos los campos son obligatorios.");
      return;
    }

    const isComplete = [
      direccionForm.direccion,
      direccionForm.codigoPostal,
      direccionForm.localidad,
      direccionForm.provincia.Descripcion,
      direccionForm.pais,
    ].every((field) => field.trim() !== "");

    setIsCompletedEntregaForm(isComplete);

    setDireccionEntrega({
      Direccion: direccionForm.direccion,
      Localidad: direccionForm.localidad,
      Codigo_Postal: direccionForm.codigoPostal,
      Provincia: {
        Codigo: direccionForm.provincia.Codigo,
        Descripcion: direccionForm.provincia.Descripcion,
      },
      Pais: direccionForm.pais,
    });

    handleToggleCambiarDireccion()

    toast.success("Direccion modificada con exito");
  };

  console.log(direccionEntrega, "direccion de entrega")


  return (
    <>
      <div>
        <form onSubmit={enviarOrden}>
          <div className="facturacion-container-checkout-estilos">
            <DatosFacturacionUser user={user} rucPais={rucPais} />
            <TipoDeEntregaSelector
              info={info}
              tiposDeEntrega={tiposDeEntrega}
              country={country}
              handleEntregaChange={handleEntregaChange}
            />
          </div>

          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <DireccionFacturacionContainer
              country={country}
              info={info}
              user={user}
            />

            <DireccionEntregaContainer
              country={country}
              info={info}
              direccionEntrega={direccionEntrega}
              user={user}
              showFormCambiarDireccion={showFormCambiarDireccion}
              handleToggleCambiarDireccion={handleToggleCambiarDireccion}
            />

            {country === "ARG" && (info.Codigo === "1" || info.Codigo === "2") && (
              <AutorizadoForm
                info={info}
                country={country}
                authorizedPerson={authorizedPerson}
                handleRetiraChange={handleRetiraChange}
                setShowModal={setShowModal}
                selectedAuthorizedPerson={selectedAuthorizedPerson}
              />
            )}
          </div>
          <hr />
        </form>
      </div>

      {
        country === "ARG" && info.Codigo !== "1" && info.Codigo !== "2" && info.Codigo !== undefined && showFormCambiarDireccion && (
          <>
            <DirectionForm
              direccionForm={direccionForm}
              handleDireccionChange={handleDireccionChange}
              handleSubmitDireccion={handleSubmitDireccion}
              provincias={provincias}
            />
          </>
        )
      }

      {showModal && <UploadDNIModal onClose={closeModal} />}
    </>
  );
};
